export const datapointColorsIcons = {
  order: 1,
  name: "Understand datapoint colors",
  keywords: "icon safezone public pink orange green yellow purple lock eye",
  subcategory: "Intro to datapoints",
  markdown: `# Understand datapoint colors

  {% inlineRouterLink articleId="what-is-a-datapoint?" %}Datapoints{% /inlineRouterLink %} use colors and icons to indicate important information about their visibility, validation status, and cost:

  | **Appearance/Status** | **Who can see?** | **What you can do** |
  |---|---|---|
  | {% inlineDatapoint state="safezone" /%} | Author | View, edit, remove, and {% inlineRouterLink articleId="share-datapoints" %}share{% /inlineRouterLink %} the data |
  | {% inlineDatapoint state="unlockable safezone shared" /%} | {% inlineRouterLink articleId="share-datapoints" %}Sharing recipients{% /inlineRouterLink %} | {% inlineRouterLink articleId="unlock-datapoints" %}Unlock the data{% /inlineRouterLink %} |
  | {% inlineDatapoint state="safezone shared" /%} | {% inlineRouterLink articleId="share-datapoints" %}Sharing recipients{% /inlineRouterLink %} | View, edit, remove, and {% inlineRouterLink articleId="share-datapoints" %}share{% /inlineRouterLink %} the data |
  | {% inlineDatapoint state="unstaked" /%} | Author | {% inlineRouterLink articleId="what-is-staking?" %}Stake credits{% /inlineRouterLink %} |
  | {% inlineDatapoint state="unpublished" /%} | Author | View, edit, remove, and {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}publish{% /inlineRouterLink %} the data |
  | {% inlineDatapoint state="unpublished shared" /%} | {% inlineRouterLink articleId="types-of-roles-in-tower-hunt" %}Team members{% /inlineRouterLink %} | View, edit, and remove the data |
  | {% inlineDatapoint state="inValidation" /%} | Author, {% inlineRouterLink articleId="share-datapoints" %}Sharing recipients{% /inlineRouterLink %}, and {% inlineRouterLink articleId="understand-validation" %}validators{% /inlineRouterLink %} | View, edit, remove, and {% inlineRouterLink articleId="vote-on-validations" %}vote{% /inlineRouterLink %} on the data |
  | {% inlineDatapoint state="inValidation shared" /%} | {% inlineRouterLink articleId="types-of-roles-in-tower-hunt" %}Team members{% /inlineRouterLink %} | View, edit, and remove the data |
  | {% inlineDatapoint state="rejected" /%} | Author | View and remove the data |
  | {% inlineDatapoint state="unlockable public" /%} | Anyone | {% inlineRouterLink articleId="unlock-datapoints" %}Unlock the data{% /inlineRouterLink %} |
  | {% inlineDatapoint state="public" /%} | Anyone | View the data, edit the data, remove the data |
  | {% inlineDatapoint state="calculation" /%} | Anyone | {% inlineRouterLink articleId="unlock-datapoints" %}Unlock{% /inlineRouterLink %} or view the related calculation data |
  | {% inlineDatapoint state="subscribeToView" /%} | Users with $0.00 balance | {% inlineRouterLink articleId="subscribe-to-tower-hunt" %}Subscribe to Tower Hunt{% /inlineRouterLink %} |

  ## Task priority

  You can link {% inlineRouterLink articleId="stay-organized-with-tasks" %}tasks{% /inlineRouterLink %} to datapoints to help yourself stay organized. When you set a priority for a task, it will display on the datapoint as long as the task is incomplete:

  | **Appearance** | **Task Priority** |
  |---|---|
  | {% inlineDatapoint state="low" /%} | Low |
  | {% inlineDatapoint state="medium" /%} | Medium |
  | {% inlineDatapoint state="high" /%} | High |

  {% callout type="tip" %}
  **Tip:** If a datapoint has more than one task linked to it, the highest priority will display.
  {% /callout %}`,
};
